import styled, { useTheme } from 'styled-components';
import SwiperCore from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useTranslations } from 'next-intl';
import { StarFill } from '@styled-icons/octicons/StarFill';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Section from '@/components/section';
import testimonials from '@data/testimonials';
import { flagForCountryCode } from '@/utils';
import { media } from '@/themes';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const TestimonialContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background.card};
  border-radius: .5em;
  box-shadow: ${props => props.theme.shadows.primary};
  display: flex;
  filter: grayscale(1);
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 2em;
  min-height: 12em;
  opacity: .5;
  padding: 1em;
  position: relative;
  text-align: center;
  transition-duration: 250ms;
  transition-property: opacity filter;

  ${media.sm`
    min-height: 15em;
  `}

  .swiper-slide-active & {
    filter: grayscale(0);
    opacity: 1;
  }

`;

const Title = styled.div`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 5px;
`;

const Message = styled.p`
  font-style: italic;
  font-size: .85em;
  margin: 0 0 1em;
`;

const Author = styled.small`
  margin: 1em 0 0;
  font-weight: 600;
`;

const Subtitle = styled.small`
  font-weight: 400;
  font-size: 0.75em;
`;

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${props => props.theme.maxContentWidth};
  text-align: center;
`;

const HeaderTitle = styled.h1`
  color: ${({ theme }) => theme.colors.typography.primary};
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1.5em;
`;

const StarContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 .5em;
`;

const Carousel = styled(Swiper)`
  align-items: center;
  padding: 1em .5em;

  .swiper-slide {
    height: 100%;
    transition: transform 250ms;

    &-active {
      transform: scale(1.1) translateX(1.5em);
      transform-origin: center;
      z-index: 10;
    }
  }
`;

const Testimonial = ({ testimonial }) => {
  return (
    <TestimonialContainer>
      <Title>{testimonial.title}</Title>
      <Message>{testimonial.message}</Message>

      <StarContainer>
        <StarFill size="1em" color="#FABC3C" />
        <StarFill size="1em" color="#FABC3C" />
        <StarFill size="1em" color="#FABC3C" />
        <StarFill size="1em" color="#FABC3C" />
        <StarFill size="1em" color="#FABC3C" />
      </StarContainer>

      <Author>{testimonial.author}{testimonial.author_title && `, ${testimonial.author_title}`}</Author>
      <Subtitle>{flagForCountryCode(testimonial.country)} {testimonial.countryName}</Subtitle>
    </TestimonialContainer>
  );
};

const Testimonials = () => {
  const theme = useTheme();
  const t = useTranslations('testimonials');

  return (
    <Section background={theme.colors.background.primary} style={{ padding: '4em 0' }}>
      <InnerContainer>
        <HeaderTitle>{t('title')}</HeaderTitle>
        <Carousel
          allowSlideNext
          allowSlidePrev
          autoplay={{ delay: 3000 }}
          loop
          pagination={{ disableOnInteraction: false, clickable: true }}
          slidesPerView={2}
          spaceBetween={16}
          watchSlidesProgress>
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={`testimonial-${index}`} modules={[Autoplay, Navigation, Pagination]}>
              <Testimonial testimonial={testimonial} />
            </SwiperSlide>
          ))}
        </Carousel>
      </InnerContainer>
    </Section>
  );
};

export default Testimonials;
